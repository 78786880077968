import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  template: '',
})
export class BaseComponent {
  protected isBusy = {
    active: false,
    message: '',
    showIcon: true,
  };

  /**
   * Variáveis de escopo para chaveamento de ações.
   */
  protected return_url?: string | null;
  protected scope_token?: string | null;
  protected scope_action?: string | null;
  protected scope_usr?: string | null;
  protected scope_ins?: string | null;
  protected scope_provider?: string | null;
  protected scope_provider_name?: string | null;
  protected scope_synced?: string | null;

  protected isValid(form: FormGroup, notificar?: boolean): boolean {
    if (!form.valid) {
      Object.values(form.controls).forEach((control) => {
        control.markAsTouched({ onlySelf: true });
      });
    }

    return form.valid;
  }

  protected processarQueryParams(activatedRoute: ActivatedRoute) {
    this.scope_action =
      activatedRoute.snapshot.queryParamMap.get('scope_action') || null;
    this.scope_token =
      activatedRoute.snapshot.queryParamMap.get('scope_token') || null;
    this.scope_usr =
      activatedRoute.snapshot.queryParamMap.get('scope_usr') || null;
    this.scope_ins =
      activatedRoute.snapshot.queryParamMap.get('scope_ins') || null;
    this.scope_provider =
      activatedRoute.snapshot.queryParamMap.get('scope_provider') || null;
    this.scope_provider_name =
      activatedRoute.snapshot.queryParamMap.get('scope_provider_name') || null;
    this.return_url =
      activatedRoute.snapshot.queryParamMap.get('return_url') || null;
    this.scope_synced =
      activatedRoute.snapshot.queryParamMap.get('scope_synced') || 'N';
  }

  protected enableBusy(message: string = '', showIcon: boolean = true) {
    this.isBusy.active = true;
    this.setBusyMessage(message);
    this.setBusyIcon(showIcon);
  }

  protected disableBusy() {
    this.isBusy.active = false;
    this.setBusyMessage('');
  }

  protected setBusyMessage(message: string) {
    this.isBusy.message = message;
  }

  protected setBusyIcon(show: boolean) {
    this.isBusy.showIcon = show;
  }
}
