import { environment } from '../../../environments/environment';
import { LoginExternoResult } from '../../features/autenticacao/login/login.model';

export class RouterExtensions {
  static processaTituloPagina(titulo: string): string {
    return `${titulo} | ${environment.aplicacao.nome}`;
  }

  static processaReturnUrlLoginExterno(
    urlExterna: string,
    loginExternoInfo: LoginExternoResult
  ) {
    return urlExterna.indexOf('?') >= 0
      ? `${urlExterna}&token=${encodeURIComponent(
          loginExternoInfo.token
        )}&usuarioId=${loginExternoInfo.usuarioId}`
      : `${urlExterna}?token=${encodeURIComponent(
          loginExternoInfo.token
        )}&usuarioId=${loginExternoInfo.usuarioId}`;
  }
}
